import React from "react";
import Back from "../common/Back";
import headhunting from "../../assets/headhunting.jpg";
import discussion from "../../assets/discussion.jpg";
import aggrement from "../../assets/Agreement2.png";
import approval from "../../assets/Authority-approval4.png";
import recruitmentProccess from "../../assets/recruitment-process2-768x576.png";
import screening from "../../assets/Screening-interview3.png";
import firstMedical from "../../assets/Medical-checkup.jpg";
import finaLinterview from "../../assets/Final-Selection2jpg-768x759.jpg";
import finaLMedical from "../../assets/final-medical-checkup3-e1602334450176.png";
import visa from "../../assets/visa-application.jpg";
import orientation from "../../assets/Foreign-language2.jpg";
import overseasEmployee from "../../assets/overseas-management.png";
import termination from "../../assets/Repatriation3-1.png";
import backhome from "../../assets/ReturnToResidence-1.png";
const HowRecruit = () => {
  return (
    <div>
      <div>
        <Back
          name="Our Recruitment"
          title="How We Recruit - Best Way To Find Talents!!"
          cover="https://img1.exportersindia.com/product_images/bc-full/2021/4/8749781/commercial-manpower-services-1619171017-5799679.jpeg"
        />
      </div>

      <div className="bg-gray-100">
        <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="absolute inset-x-0 top-0 items-center justify-center hidden overflow-hidden md:flex md:inset-y-0">
            <svg
              viewBox="0 0 88 88"
              className="w-full max-w-screen-xl text-indigo-100"
            >
              <circle fill="currentColor" cx="44" cy="44" r="15.5" />
              <circle
                fillOpacity="0.2"
                fill="currentColor"
                cx="44"
                cy="44"
                r="44"
              />
              <circle
                fillOpacity="0.2"
                fill="currentColor"
                cx="44"
                cy="44"
                r="37.5"
              />
              <circle
                fillOpacity="0.3"
                fill="currentColor"
                cx="44"
                cy="44"
                r="29.5"
              />
              <circle
                fillOpacity="0.3"
                fill="currentColor"
                cx="44"
                cy="44"
                r="22.5"
              />
            </svg>
          </div>
          <div className="relative grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={headhunting} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Inquiring Recruitment
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={discussion} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Discussing Employment Condition
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={aggrement} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Signing Manpower Supply Agreement
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={approval} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">Authority Approval</p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={recruitmentProccess} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Recruitment Process
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={screening} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Screening Interview
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={firstMedical} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  First Medical Checkup
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={finaLinterview} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Final Interview and Recruitment
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={finaLMedical} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Final Medical Checkup
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={visa} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">Visa Application</p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={orientation} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Orientation and Foreign Language training
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={overseasEmployee} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Overseas Employment Management
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={termination} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Repatriation or Termination
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
            <div className="flex flex-col justify-between overflow-hidden text-left transition-shadow duration-200 bg-white rounded shadow-xl group hover:shadow-2xl">
              <div className="p-5">
                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full bg-indigo-50">
                  <img src={backhome} className="" alt=""></img>
                </div>
                <p className="mb-2 font-bold text-center">
                  Return to Residence
                </p>
              </div>
              <div className="w-full h-1 ml-auto duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowRecruit;
